import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { apiKey, baseUrl } from "src/environments/environment";
import * as moment from 'moment';
import 'moment-timezone';

@Injectable({
    providedIn: "root",
})
export class SharedService {

    constructor(
        private http: HttpClient
    ) { }

    private spinnerStatus = new Subject<boolean>();
    setSpinnerStatus(status: boolean) {
        this.spinnerStatus.next(status);
    }

    getSpinnerStatus() {
        return this.spinnerStatus.asObservable();
    }
    
    getUTCtoCurrentTime(utctime: any, timezone: string, format: string) {
        if (timezone && timezone != '') {
            return moment.utc(utctime).tz(timezone).format(format);
        } else {
            return utctime; //This is for old records just in case there is no timezone
        }
    }

    getUTCtoCurrentDate(utctime: any, timezone: string) {
        if (timezone && timezone != '') {
            return moment.utc(utctime).tz(timezone);
        } else {
            return utctime; //This is for old records just in case there is no timezone
        }
    }

    async savedocumentattachment(myfile: File) {
        const postData = new FormData();
        postData.append("myfile", myfile, "document");
        return new Promise((resolve, reject) => {
            this.http
                .post(baseUrl + "/api/imageuploader/savedocument", postData, {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        "bearer " + ''
                    ),
                })
                .subscribe(
                    (res) => {
                        resolve(res);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    }

    async getCombinedSubcategoriesLists(scDetails: any) {
        return new Promise((resolve, reject) => {
            this.http.post(baseUrl + '/api/subcategory/getcombinedlist',
                JSON.stringify(scDetails),
                {
                    headers: new HttpHeaders().set('Content-Type', 'application/json')
                })
                .subscribe(res => {
                    resolve(res);
                }, (err) => {
                    reject(err);
                });
        });
    }
}