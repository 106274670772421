import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { ActivitymasterService } from './activitymaster.service';

@Injectable({ providedIn: 'root' })
export class ActivitymasterResolverService implements Resolve<any[]> {
  constructor(
    private superCategoryService: ActivitymasterService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const activitymaster = this.superCategoryService.getActivitymaster();
    if (activitymaster.length === 0) {
      return this.superCategoryService.getAllActivitymaster();
    } else {
      return activitymaster;
    }
  }
}
