import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { SubCategoryService } from './subcategory.service';

@Injectable({ providedIn: 'root' })
export class SubcategoryResolverService implements Resolve<any[]> {
  constructor(
    private subCategoryService: SubCategoryService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const subcategories = this.subCategoryService.getSubCategories();
    if (subcategories.length === 0) {
      return this.subCategoryService.getAllSubCategories();
    } else {
      return subcategories;
    }
  }
}
