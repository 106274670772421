<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-gutter flex items-center">
  <button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async" [routerLink]="['/']" class="ltr:mr-4 rtl:ml-4 block flex items-center">
    <img alt="Logo" class="w-8 select-none" src="assets/img/demo/logo.svg" />
    <h1 [class.hidden]="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">Shilparamam</h1>
  </a>

  <div>
  <p style="font-size: 20px; font-weight: 600;">Shilparamam</p>
  <p style="font-size: 14px">Adminstration</p>
</div>

  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async" [class.hidden]="mobileQuery"
    class="px-gutter flex-none flex items-center">
    <ux-navigation-item *ngFor="let item of navigationItems" [item]="item"></ux-navigation-item>
  </div>

  <span class="flex-1"></span>
</div>

<ux-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
  [class.hidden]="mobileQuery"></ux-navigation>