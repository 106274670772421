import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { apiKey, baseUrl } from 'src/environments/environment';
import { Subcategory } from './interfaces/subcategory.model';

@Injectable({ providedIn: 'root' })
export class SubCategoryService {
    private sub_categories: any[] = [];
    sub_categoriesChanged = new Subject<any[]>();

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    getAllSubCategories() {
        return this.http.get(baseUrl + '/api/subcategory/getallsubcategories',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                map((subcategories: any) => {
                    return subcategories.subcategories;
                }),
                tap((subcategories: any) => {
                    this.setSubCategories(subcategories);
                })
            )
    }

    setSubCategories(subcategories: any[]) {
        this.sub_categories = subcategories;
        this.sub_categoriesChanged.next(this.sub_categories.slice());
    }


    getSubCategories() {
        return this.sub_categories.slice();
    }

    saveSubCategory(subcategory: any) {
        this.http.post(baseUrl + '/api/subcategory/',
            subcategory,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .subscribe((response: any) => {
                if (response.messagecode === 100) {
                    this.sub_categories.unshift(response.subcategory);
                    this.sub_categoriesChanged.next(this.sub_categories.slice());
                } else {
                    //find the super category in existing list and udpate it
                    let objIdx = this.sub_categories.findIndex(x => x._id === response.subcategory._id);
                    if (objIdx != -1) {
                        this.sub_categories[objIdx] = response.subcategory;
                        this.sub_categoriesChanged.next(this.sub_categories.slice());
                    }
                }
            });
    }

    deleteSubCategory(id: string) {
        this.http.delete(baseUrl + '/api/subcategory/' + id,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .subscribe((response: any) => {
                if (response.messagecode === 100) {
                    //find the id in list and remove it
                    let objIdx = this.sub_categories.findIndex(x => x._id === id);
                    if (objIdx != -1) {
                        this.sub_categories.splice(objIdx, 1);
                        this.sub_categoriesChanged.next(this.sub_categories.slice());
                    }
                } else {
                    
                }
            });
    }

    deleteSubCategories() {
        return this.http.get(baseUrl + '/api/subcategory/getallsubcategories',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                map((subcategories: any) => {
                    return subcategories.subcategories;
                }),
                tap((subcategories: any) => {
                    this.setSubCategories(subcategories);
                })
            )
    }
}