<div (mouseenter)="collapseOpenSidenav()"
     (mouseleave)="collapseCloseSidenav()"
     [class.collapsed]="collapsed"
     [class.open]="collapsed && collapsedOpen$ | async"
     class="sidenav flex flex-col">
  <div class="ux-sidenav-toolbar__container">
    <div class="sidenav-toolbar flex-none flex items-center" style="padding-top: 15px;">
      <img [src]="imageUrl$ | async" alt="Logo" class="w-6 select-none" style="width: 80%;"/>
      <span class="ux-sidenav-toolbar__headline flex-auto" style="font-size: 1.0rem; display: block;"></span>
      <button (click)="toggleCollapse()"
              *ngIf="showCollapsePin$ | async"
              class="w-8 h-8 -mr-2 leading-none flex-none hidden lg:block"
              mat-icon-button
              type="button">
        <mat-icon *ngIf="!collapsed"
                  class="text-[0.875rem] w-[0.875rem] h-[0.875rem]"
                  svgIcon="mat:radio_button_checked"></mat-icon>
        <mat-icon *ngIf="collapsed"
                  class="text-[0.875rem] w-[0.875rem] h-[0.875rem]"
                  svgIcon="mat:radio_button_unchecked"></mat-icon>
      </button>
    </div>
  </div>

  <ux-scrollbar class="flex-1 flex flex-col">
    <div class="pt-6 flex-1">
      <ux-sidenav-item *ngFor="let item of items; trackBy: trackByRoute"
                        [item]="item"
                        [level]="0"></ux-sidenav-item>
    </div>

    <div class="ux-sidenav-footer flex items-center justify-center opacity-20 px-6 py-6 select-none flex-none">
      <img [src]="imageUrl$ | async" alt="Logo" class="w-8 select-none"/>
    </div>
  </ux-scrollbar>

  <div *ngIf="userVisible$ | async"
       class="ux-sidenav-user__container flex-none">
    <div #userProfileMenuOriginRef
         (click)="openProfileMenu(userProfileMenuOriginRef)"
         [class.ux-sidenav-user--open]="userMenuOpen$ | async"
         class="ux-sidenav-user flex items-center"
         matRipple
         matRippleColor="var(--sidenav-item-ripple-color)">
      <div class="ux-sidenav-user__content flex-auto">
        <div class="ux-sidenav-user__title">{{ user }}</div>
        <div class="ux-sidenav-user__subtitle">{{ email }}</div>
      </div>
      <mat-icon class="ux-sidenav-user__dropdown-icon flex-none" svgIcon="mat:arrow_drop_down"></mat-icon>
    </div>
  </div>
</div>
