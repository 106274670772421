import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { UxRoutes } from '../ux/interfaces/ux-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard } from './auth/auth-guard';
import { SupercategoryResolverService } from './pages/setup/supercategory/supercategory-resolver.service';
import { SubcategoryResolverService } from './pages/setup/subcategory/subcategory-resolver.service';
import { GroupsResolverService } from './pages/users/groups/groups-resolver.service';
import { UsersResolverService } from './pages/users/users/users-resolver.service';
import { UnitmasterResolverService } from './pages/setup/unitmaster/unitmaster-resolver.service';
import { ActivitymasterResolverService } from './pages/setup/activitymaster/activitymaster-resolver.service';

const routes: UxRoutes = [
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'home',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
      },
      {
        path: 'administration',
        children: [
          {
            path: 'posmanagement',
            loadChildren: () => import('./pages/productmanagement/posmanagement/posmanagement.module').then(m => m.PosmanagementModule),
            data: {
              toolbarShadowEnabled: true
            },
            //resolve: [ProductsResolverService]
          }
        ]
      },
      {
        path: 'reports',
        children: [
          {
            path: 'salesreport',
            loadChildren: () => import('./pages/reports/salesreport/salesreport.module').then(m => m.SalesreportModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'activitywisereport',
            loadChildren: () => import('./pages/reports/activitywisereport/activitywisereport.module').then(m => m.ActivitywisereportModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'ticketsalesreport',
            loadChildren: () => import('./pages/reports/ticketsalesreport/ticketsalesreport.module').then(m => m.TicketsalesreportModule),
            data: {
              toolbarShadowEnabled: true
            }
          }
        ]
      },
      {
        path: 'setup',
        children: [
          {
            path: 'supercategory',
            loadChildren: () => import('./pages/setup/supercategory/supercategory.module').then(m => m.SupercategoryModule),
            data: {
              toolbarShadowEnabled: true
            },
            resolve: [SupercategoryResolverService]
          },
          {
            path: 'subcategory',
            loadChildren: () => import('./pages/setup/subcategory/subcategory.module').then(m => m.SubcategoryModule),
            data: {
              toolbarShadowEnabled: true
            },
            resolve: [SubcategoryResolverService]
          },
          {
            path: 'unitmaster',
            loadChildren: () => import('./pages/setup/unitmaster/unitmaster.module').then(m => m.UnitmasterModule),
            data: {
              toolbarShadowEnabled: true
            },
            resolve: [UnitmasterResolverService]
          },
          {
            path: 'activitymaster',
            loadChildren: () => import('./pages/setup/activitymaster/activitymaster.module').then(m => m.ActivitymasterModule),
            data: {
              toolbarShadowEnabled: true
            },
            resolve: [ActivitymasterResolverService]
          }
        ]
      },
      {
        path: 'users',
        children: [
          {
            path: 'groups',
            loadChildren: () => import('./pages/users/groups/groups.module').then(m => m.GroupsModule),
            data: {
              toolbarShadowEnabled: true
            },
            resolve: [GroupsResolverService]
          },
          {
            path: 'manageusers',
            loadChildren: () => import('./pages/users/users/users.module').then(m => m.UsersModule),
            data: {
              toolbarShadowEnabled: true
            },
            resolve: [UsersResolverService]
          }
        ]
      },
      {
        path: '**',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled', useHash: true
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
