import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../ux/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../ux/services/navigation.service';
import { LayoutService } from '../ux/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../ux/services/splash-screen.service';
import { UxConfigName } from '../ux/config/config-name.model';
import { ColorSchemeName } from '../ux/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../ux/components/config-panel/color-variables';

@Component({
  selector: 'ux-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private configService: ConfigService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService,
              private readonly matIconRegistry: MatIconRegistry,
              private readonly domSanitizer: DomSanitizer) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as UxConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });

    /**
     * Add your own routes here
     */
    this.navigationService.items = [
      {
        type: 'subheading',
        label: 'Dashboards',
        children: [
          {
            type: 'link',
            label: 'Notifications',
            route: '/home',
            icon: 'mat:notifications',
            routerLinkActiveOptions: { exact: true }
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Administration',
        children: [
          {
            type: 'link',
            label: 'POS Management',
            route: '/home/administration/posmanagement',
            icon: 'mat:point_of_sale'
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Reports',
        children: [
          {
            type: 'link',
            label: 'Sales Report',
            route: '/home/reports/salesreport',
            icon: 'mat:summarize'
          },{
            type: 'link',
            label: 'Activity Wise Report',
            route: '/home/reports/activitywisereport',
            icon: 'mat:assignment'
          },{
            type: 'link',
            label: 'Ticket Sales Report',
            route: '/home/reports/ticketsalesreport',
            icon: 'mat:confirmation_number'
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Admin User Management',
        children: [
          {
            type: 'link',
            label: 'Groups',
            route: '/home/users/groups',
            icon: 'mat:diversity_3'
          },
          {
            type: 'link',
            label: 'Group Privileges',
            route: '/home/users/groupprivileges',
            icon: 'mat:group_add'
          },
          {
            type: 'link',
            label: 'Manage Users',
            route: '/home/users/manageusers',
            icon: 'mat:manage_accounts'
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Setup',
        children: [
          {
            type: 'link',
            label: 'Unit Master',
            route: '/home/setup/unitmaster',
            icon: 'mat:assistant_direction'
          },
          {
            type: 'link',
            label: 'Activity Master',
            route: '/home/setup/activitymaster',
            icon: 'mat:pool'
          },
          {
            type: 'link',
            label: 'Super Category',
            route: '/home/setup/supercategory',
            icon: 'mat:category'
          },
          {
            type: 'link',
            label: 'Sub Category',
            route: '/home/setup/subcategory',
            icon: 'mat:category'
          }
        ]
      },
    ];
  }
}
