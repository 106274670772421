import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { apiKey } from 'src/environments/environment';
import { SharedService } from '../shared/shared.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService,
        private sharedService: SharedService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        //The below delay is required for the sidebar to close and then the spinner starts spinning.
        //If the timer timeout is removed, then the sidebar is failing while collapsing it
        
        setTimeout(() => {
            this.sharedService.setSpinnerStatus(true); //Initiate loader
        }, 200);
        
        const authToken = this.authService.getToken();
        const a_loggedin_id = this.authService.getLoggedInID();
        if (authToken) {
            const authRequest = req.clone({
                headers: req.headers
                    .set('Authorization', "bearer " + authToken)
                    .set('x-user', a_loggedin_id)
                    .set('x-api-key', apiKey)
            });
            return next.handle(authRequest).pipe(finalize(() => {
                setTimeout(() => {
                    this.sharedService.setSpinnerStatus(false);
                    //Closing loader when you have Error    
                }, 1000);
            }))
        } else {
            const authRequest = req.clone({
                headers: req.headers
                    .set('x-api-key', apiKey)
            });
            return next.handle(authRequest).pipe(finalize(() => {
                setTimeout(() => {
                    this.sharedService.setSpinnerStatus(false);
                    //Closing loader when you have Error    
                }, 1000);
                
            }))
        }
    }
}