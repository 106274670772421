import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { apiKey, baseUrl } from 'src/environments/environment';
import { Unitmaster } from './interfaces/unitmaster.model';

@Injectable({ providedIn: 'root' })
export class UnitmasterService {
    private unitmaster: any[] = [];
    unitmasterChanged = new Subject<any[]>();

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    private units: any[] = [];
    unitsChanged = new Subject<any[]>();
    getAllUnits() {
        this.http.get<any>(baseUrl + '/api/unitmaster/getallunits',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .subscribe(units => {
                this.units = units.units;
                this.unitsChanged.next(this.units.slice());
            })
    }

    getAllUnitmaster() {
        return this.http.get(baseUrl + '/api/unitmaster/getallunits',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                map((unitmaster: any) => {
                    return unitmaster.units;
                }),
                tap((unitmaster: any) => {
                    this.setUnitmaster(unitmaster);
                })
            )
    }

    setUnitmaster(unitmaster: any[]) {
        this.unitmaster = unitmaster;
        this.unitmasterChanged.next(this.unitmaster.slice());
    }


    getUnitmaster() {
        return this.unitmaster.slice();
    }

    saveUnitmaster(unitmaster: any) {
        this.http.post(baseUrl + '/api/unitmaster/',
            unitmaster,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .subscribe((response: any) => {
                if (response.messagecode === 100) {
                    this.unitmaster.unshift(response.unit);
                    this.unitmasterChanged.next(this.unitmaster.slice());
                } else {
                    //find the super category in existing list and udpate it
                    let objIdx = this.unitmaster.findIndex(x => x._id === response.unit._id);
                    if (objIdx != -1) {
                        this.unitmaster[objIdx] = response.unit;
                        this.unitmasterChanged.next(this.unitmaster.slice());
                    }
                }
            });
    }

    deleteGroup(id: string) {
        this.http.delete(baseUrl + '/api/unitmaster/' + id,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .subscribe((response: any) => {
                if (response.messagecode === 100) {
                    //find the id in list and remove it
                    let objIdx = this.unitmaster.findIndex(x => x._id === id);
                    if (objIdx != -1) {
                        this.unitmaster.splice(objIdx, 1);
                        this.unitmasterChanged.next(this.unitmaster.slice());
                    }
                } else {
                    
                }
            });
    }

    deleteUnitmaster() {
        return this.http.get(baseUrl + '/api/unitmaster/getallunits',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                map((unitmaster: any) => {
                    return unitmaster.units;
                }),
                tap((unitmaster: any) => {
                    this.setUnitmaster(unitmaster);
                })
            )
    }

    private unitnotifications: any[] = [];
    unitnotificationsChanged = new Subject<any[]>();
    getAllUnitNotifications() {
        this.http.get<any>(baseUrl + '/api/notifications/getnotificationbyunits',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .subscribe(units => {
                this.unitnotifications = units.unitnotifications;
                this.unitnotificationsChanged.next(this.unitnotifications.slice());
            })
    }
}