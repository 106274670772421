import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { apiKey, baseUrl } from 'src/environments/environment';
import { SharedService } from '../shared/shared.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private sharedService: SharedService
  ) { }

  signIn(credentials: any) {
    return new Promise((resolve, reject) => {
      this.http.post(baseUrl + '/api/users/login',
        JSON.stringify(credentials),
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        })
        .subscribe((res: any) => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getToken() {
    let logindata = JSON.parse(localStorage.getItem('apgovadmin_logininfo'));
    if (logindata) {
      return logindata.token;
    } else {
      this.onLogout();
    }
  }

  getLoggedInID() {
    let logindata = JSON.parse(localStorage.getItem('apgovadmin_logininfo'));
    if (logindata) {
      return logindata.userid
    } else {
      this.onLogout();
    }
  }

  getLoggedInName() {
    let logindata = JSON.parse(localStorage.getItem('apgovadmin_logininfo'));
    console.log(logindata)
    if (logindata) {
      return logindata.first_name + ' ' + logindata.last_name;
    } else {
      this.onLogout();
    }
  }

  getLoggedInEmail() {
    let logindata = JSON.parse(localStorage.getItem('apgovadmin_logininfo'));
    console.log(logindata)
    if (logindata) {
      return logindata.email;
    } else {
      this.onLogout();
    }
  }

  getUserTimeZone() {
    let logindata = JSON.parse(localStorage.getItem('apgovadmin_logininfo'));
    if (logindata) {
      return logindata.time_zone;
    } else {
      this.onLogout();
    }
  }

  getCompanyDateTimeFormat() {
    let logindata = JSON.parse(localStorage.getItem('apgovadmin_logininfo'));
    if (logindata) {
      return logindata.date_format + ' ' + logindata.time_format;
    } else {
      this.onLogout();
    }
  }

  async onLogout() {
    localStorage.clear();
    this.router.navigate(['/']);
    this.setUserAuthenticationStatus(false);
  }

  _authenticationData: any;
  private authenticationDataUpdated = new Subject<boolean>();
  setUserAuthenticationStatus(authData: boolean) {
    this._authenticationData = authData;
    this.authenticationDataUpdated.next(this._authenticationData);
  }

  async getUserAuthenticationStatus() {
    let logindata = await JSON.parse(localStorage.getItem('apgovadmin_logininfo'));
    if (logindata) {
      this.setUserAuthenticationStatus(true);
    } else {
      this.setUserAuthenticationStatus(false);
    }
  }

  getAuthenticationDataUpdatedListener() {
    return this.authenticationDataUpdated.asObservable();
  }
}