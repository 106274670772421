import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { apiKey, baseUrl } from 'src/environments/environment';
import { Activitymaster } from './interfaces/activitymaster.model';

@Injectable({ providedIn: 'root' })
export class ActivitymasterService {
    private activitymaster: any[] = [];
    activitymasterChanged = new Subject<any[]>();

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    private activities: any[] = [];
    activitiesChanged = new Subject<any[]>();
    getAllActivties() {
        this.http.get<any>(baseUrl + '/api/activitymaster/getallactivities',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .subscribe(activities => {
                this.activities = activities.activities;
                this.activitiesChanged.next(this.activities.slice());
            })
    }

    getAllActivitymaster() {
        return this.http.get(baseUrl + '/api/activitymaster/getallactivities',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                map((activitymaster: any) => {
                    console.log(activitymaster)
                    return activitymaster.activities;
                }),
                tap((activitymaster: any) => {
                    this.setActivitymaster(activitymaster);
                })
            )
    }

    setActivitymaster(activitymaster: any[]) {
        this.activitymaster = activitymaster;
        console.log(this.activitymaster)
        this.activitymasterChanged.next(this.activitymaster.slice());
    }


    getActivitymaster() {
        return this.activitymaster.slice();
    }

    saveActivitymaster(activitymaster: any) {
        this.http.post(baseUrl + '/api/activitymaster/',
            activitymaster,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .subscribe((response: any) => {
                if (response.messagecode === 100) {
                    this.activitymaster.unshift(response.activity);
                    this.activitymasterChanged.next(this.activitymaster.slice());
                } else {
                    //find the super category in existing list and udpate it
                    let objIdx = this.activitymaster.findIndex(x => x._id === response.activity._id);
                    if (objIdx != -1) {
                        this.activitymaster[objIdx] = response.activity;
                        this.activitymasterChanged.next(this.activitymaster.slice());
                    }
                }
            });
    }

    deleteGroup(id: string) {
        this.http.delete(baseUrl + '/api/activitymaster/' + id,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .subscribe((response: any) => {
                if (response.messagecode === 100) {
                    //find the id in list and remove it
                    let objIdx = this.activitymaster.findIndex(x => x._id === id);
                    if (objIdx != -1) {
                        this.activitymaster.splice(objIdx, 1);
                        this.activitymasterChanged.next(this.activitymaster.slice());
                    }
                } else {
                    
                }
            });
    }

    deleteActivitymaster() {
        return this.http.get(baseUrl + '/api/activitymaster/getallactivities',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                map((activitymaster: any) => {
                    return activitymaster.activities;
                }),
                tap((activitymaster: any) => {
                    this.setActivitymaster(activitymaster);
                })
            )
    }
}