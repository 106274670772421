<div class="ux-user-menu">
  <a (click)="close()"
     [routerLink]="['/apps/social']"
     class="ux-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="ux-user-menu-item__icon icon-sm" svgIcon="mat:account_circle"></mat-icon>
    <div class="ux-user-menu-item__label">Your Profile</div>
  </a>

  <a (click)="close()"
     [routerLink]="['/']"
     class="ux-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="ux-user-menu-item__icon icon-sm" svgIcon="mat:logout"></mat-icon>
    <div class="ux-user-menu-item__label">Sign Out</div>
  </a>
</div>
