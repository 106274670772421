<ng-template #sidenavRef>
  <ux-sidenav [collapsed]="sidenavCollapsed$ | async"></ux-sidenav>
</ng-template>

<ng-template #toolbarRef>
  <ux-toolbar [hasShadow]="toolbarShadowEnabled$ | async"
               [mobileQuery]="!(isDesktop$ | async)"
               class="ux-toolbar"></ux-toolbar>
</ng-template>

<ng-template #footerRef>
  <ux-footer *ngIf="isFooterVisible$ | async" class="ux-footer"></ux-footer>
</ng-template>

<ng-template #quickpanelRef>
  <ux-quickpanel></ux-quickpanel>
</ng-template>

<ux-layout [footerRef]="footerRef"
            [quickpanelRef]="quickpanelRef"
            [sidenavRef]="sidenavRef"
            [toolbarRef]="toolbarRef"></ux-layout>

<!-- <ux-config-panel-toggle (openConfig)="configpanel.open()"></ux-config-panel-toggle> -->

<!-- CONFIGPANEL -->
<ux-sidebar #configpanel [invisibleBackdrop]="true" position="right">
  <ux-config-panel></ux-config-panel>
</ux-sidebar>
<!-- END CONFIGPANEL -->
