import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { apiKey, baseUrl } from 'src/environments/environment';
import { Groups } from './interfaces/groups.model';

@Injectable({ providedIn: 'root' })
export class GroupsService {
    private groups: any[] = [];
    groupsChanged = new Subject<any[]>();

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    getAllGroups() {
        return this.http.get(baseUrl + '/api/groups/getallgroups',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                map((groups: any) => {
                    return groups.groups;
                }),
                tap((groups: any) => {
                    this.setGroups(groups);
                })
            )
    }

    setGroups(groups: any[]) {
        this.groups = groups;
        this.groupsChanged.next(this.groups.slice());
    }


    getGroups() {
        return this.groups.slice();
    }

    saveGroups(groups: any) {
        this.http.post(baseUrl + '/api/groups/',
            groups,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .subscribe((response: any) => {
                if (response.messagecode === 100) {
                    this.groups.unshift(response.group);
                    this.groupsChanged.next(this.groups.slice());
                } else {
                    //find the super category in existing list and udpate it
                    let objIdx = this.groups.findIndex(x => x._id === response.group._id);
                    if (objIdx != -1) {
                        this.groups[objIdx] = response.group;
                        this.groupsChanged.next(this.groups.slice());
                    }
                }
            });
    }

    deleteGroup(id: string) {
        this.http.delete(baseUrl + '/api/groups/' + id,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .subscribe((response: any) => {
                if (response.messagecode === 100) {
                    //find the id in list and remove it
                    let objIdx = this.groups.findIndex(x => x._id === id);
                    if (objIdx != -1) {
                        this.groups.splice(objIdx, 1);
                        this.groupsChanged.next(this.groups.slice());
                    }
                } else {
                    
                }
            });
    }

    deleteGroups() {
        return this.http.get(baseUrl + '/api/groups/getallgroups',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                map((groups: any) => {
                    return groups.groups;
                }),
                tap((groups: any) => {
                    this.setGroups(groups);
                })
            )
    }
}