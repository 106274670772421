import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { GroupsService } from './groups.service';

@Injectable({ providedIn: 'root' })
export class GroupsResolverService implements Resolve<any[]> {
  constructor(
    private superCategoryService: GroupsService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const groups = this.superCategoryService.getGroups();
    if (groups.length === 0) {
      return this.superCategoryService.getAllGroups();
    } else {
      return groups;
    }
  }
}
