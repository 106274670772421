import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { apiKey, baseUrl } from 'src/environments/environment';
import { Users } from './interfaces/users.model';

@Injectable({ providedIn: 'root' })
export class UserService {
    private users: any[] = [];
    usersChanged = new Subject<any[]>();

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    getAllUsers() {
        return this.http.get(baseUrl + '/api/users/getallusers',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                map((users: any) => {
                    return users.users;
                }),
                tap((users: any) => {
                    this.setUsers(users);
                })
            )
    }

    setUsers(users: any[]) {
        this.users = users;
        this.usersChanged.next(this.users.slice());
    }


    getUsers() {
        return this.users.slice();
    }

    saveUser(users: any) {
        this.http.post(baseUrl + '/api/users/',
            users,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .subscribe((response: any) => {
                if (response.messagecode === 100) {
                    this.users.unshift(response.user);
                    this.usersChanged.next(this.users.slice());
                } else {
                    //find the object in existing list and udpate it
                    let objIdx = this.users.findIndex(x => x._id === response.user._id);
                    if (objIdx != -1) {
                        this.users[objIdx] = response.user;
                        this.usersChanged.next(this.users.slice());
                    }
                }
            });
    }

    deleteUser(id: string) {
        this.http.delete(baseUrl + '/api/users/' + id,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .subscribe((response: any) => {
                if (response.messagecode === 100) {
                    //find the id in list and remove it
                    let objIdx = this.users.findIndex(x => x._id === id);
                    if (objIdx != -1) {
                        this.users.splice(objIdx, 1);
                        this.usersChanged.next(this.users.slice());
                    }
                } else {
                    
                }
            });
    }

    deleteUsers() {
        return this.http.get(baseUrl + '/api/users/getallusers',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                map((users: any) => {
                    return users.users;
                }),
                tap((users: any) => {
                    this.setUsers(users);
                })
            )
    }
}