import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { apiKey, baseUrl } from 'src/environments/environment';
import { Supercategory } from './interfaces/supercategory.model';

@Injectable({ providedIn: 'root' })
export class SuperCategoryService {
    private super_categories: any[] = [];
    super_categoriesChanged = new Subject<any[]>();

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    getAllSuperCategories() {
        return this.http.get(baseUrl + '/api/supercategory/getallsupercategories',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                map((supercategories: any) => {
                    return supercategories.supercategories;
                }),
                tap((supercategories: any) => {
                    this.setSuperCategories(supercategories);
                })
            )
    }

    setSuperCategories(supercategories: any[]) {
        this.super_categories = supercategories;
        this.super_categoriesChanged.next(this.super_categories.slice());
    }


    getSuperCategories() {
        return this.super_categories.slice();
    }

    saveSuperCategory(supercategory: any) {
        this.http.post(baseUrl + '/api/supercategory/',
            supercategory,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .subscribe((response: any) => {
                if (response.messagecode === 100) {
                    this.super_categories.unshift(response.supercategory);
                    this.super_categoriesChanged.next(this.super_categories.slice());
                } else {
                    //find the super category in existing list and udpate it
                    let objIdx = this.super_categories.findIndex(x => x._id === response.supercategory._id);
                    if (objIdx != -1) {
                        this.super_categories[objIdx] = response.supercategory;
                        this.super_categoriesChanged.next(this.super_categories.slice());
                    }
                }
            });
    }

    deleteSuperCategory(id: string) {
        this.http.delete(baseUrl + '/api/supercategory/' + id,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .subscribe((response: any) => {
                if (response.messagecode === 100) {
                    //find the id in list and remove it
                    let objIdx = this.super_categories.findIndex(x => x._id === id);
                    if (objIdx != -1) {
                        this.super_categories.splice(objIdx, 1);
                        this.super_categoriesChanged.next(this.super_categories.slice());
                    }
                } else {
                    
                }
            });
    }

    deleteSuperCategories() {
        return this.http.get(baseUrl + '/api/supercategory/getallsupercategories',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                map((supercategories: any) => {
                    return supercategories.supercategories;
                }),
                tap((supercategories: any) => {
                    this.setSuperCategories(supercategories);
                })
            )
    }
}