import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { UserService } from './users.service';

@Injectable({ providedIn: 'root' })
export class UsersResolverService implements Resolve<any[]> {
  constructor(
    private usersService: UserService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const users = this.usersService.getUsers();
    if (users.length === 0) {
      return this.usersService.getAllUsers();
    } else {
      return users;
    }
  }
}
